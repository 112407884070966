import { searchClient as createClient } from "@cappelendamm/product-search";

const algoliaAppId = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID ?? "";
const algoliaApiKey = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY ?? "";
export const bookIndex = process.env.NEXT_PUBLIC_ALGOLIA_BOOK_INDEX ?? "";
export const authorIndex = process.env.NEXT_PUBLIC_ALGOLIA_AUTHOR_INDEX ?? "";
export const querySuggestionsIndex =
  process.env.NEXT_PUBLIC_ALGOLIA_QUERY_SUGGESTIONS_INDEX ?? "";

export const searchClient = createClient(algoliaAppId, algoliaApiKey);
